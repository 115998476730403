import React from "react";
import { CommentList } from "react-activity-feed";

import { ReactComponent as Bird } from "../../assets/bird.svg";

import CommentItem from "./CommentItem";

const EmptyCommentsState = () => {
  return (
    <div className="ThreadBirdContainerBox" style={{ height: "40vh" }}>
      <Bird />
      <span className="WaitingCommunityText">
        Waiting for the community to flock to your post
      </span>
    </div>
  );
};

export default function PostComments({ activity }) {
  if (!activity) return null;

  const { reaction_counts } = activity;
  const commentCount = reaction_counts?.comment || 0;

  if (commentCount === 0) return <EmptyCommentsState />;

  return (
    <CommentList
      activityId={activity.id}
      CommentItem={({ comment }) => <CommentItem comment={comment} />}
    />
  );
}
