import React from "react";

import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";

import { useNavigate, useParams } from "react-router-dom";
import { CreatePostHeader } from "../../components/CreatePost/CreatePostHeader";

import { ReactComponent as Construction } from "../../assets/Construction.svg";
import { ReactComponent as EmojiSilence } from "../../assets/EmojiSilence.svg";
import { ReactComponent as EmojiDoctor } from "../../assets/EmojiDoctor.svg";
import { ReactComponent as EmojiBrain } from "../../assets/EmojiBrain.svg";
import { ReactComponent as EmojiRedFlag } from "../../assets/EmojiRedFlag.svg";
import { ReactComponent as CaretRight } from "../../assets/CaretRight.svg";

import "./Guidelines.css";
import {
  GUIDELINES_COMMUNITY_MEMBERS,
  GUIDELINES_INTELLECTUAL_PROPERTY,
  GUIDELINES_PATIENT_PRIVACY,
  GUIDELINES_VIOLATE_COMMUNITY_GUIDELINES,
} from "../../routes";
import { Header } from "../Settings/components/Header";

function GuidelinesPatientPrivacyInner() {
  const navigate = useNavigate();

  const navigateBack = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  return (
    <div style={{ maxWidth: "720px", width: "100%" }}>
      <Header title={"Community Guidelines"} showTitle={false} />
      <div className="flex flex-col pt-4 pb-4 gap-4 pl-4 pr-4">
        <EmojiBrain width={40} height={40} />
        <span className="GuidelinesTitle">
          Acknowledge and Respect Intellectual Property
        </span>
        <span className="GuidelinesDescription">
          We're all about celebrating each other's ideas in this community. If
          you're borrowing thoughts, info, or work from others, be sure to give
          them a loud and proud shout-out.
        </span>
        <span className="GuidelinesDescription">
          Keeping things transparent and truthful is our game, and we're
          dedicated to maintaining the professional standards we set for
          ourselves.
        </span>
        <span className="GuidelinesDescription">
          Just a friendly reminder - it's never cool to copy. Let's keep it
          original, shall we?
        </span>
      </div>
    </div>
  );
}

export default function GuidelinesIntellectualProperty(props) {
  return (
    <AuthenticatedLayout
      {...props}
      backgroundColor="#FBFDFE"
      hideHeader={true}
      hideFooter={true}
    >
      <GuidelinesPatientPrivacyInner {...props} />
    </AuthenticatedLayout>
  );
}
