import React from "react";

import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";

import { useNavigate, useParams } from "react-router-dom";
import { CreatePostHeader } from "../../components/CreatePost/CreatePostHeader";

import { ReactComponent as Construction } from "../../assets/Construction.svg";
import { ReactComponent as EmojiSilence } from "../../assets/EmojiSilence.svg";
import { ReactComponent as EmojiDoctor } from "../../assets/EmojiDoctor.svg";
import { ReactComponent as EmojiBrain } from "../../assets/EmojiBrain.svg";
import { ReactComponent as EmojiRedFlag } from "../../assets/EmojiRedFlag.svg";
import { ReactComponent as CaretRight } from "../../assets/CaretRight.svg";

import "./Guidelines.css";
import {
  GUIDELINES_COMMUNITY_MEMBERS,
  GUIDELINES_INTELLECTUAL_PROPERTY,
  GUIDELINES_PATIENT_PRIVACY,
  GUIDELINES_VIOLATE_COMMUNITY_GUIDELINES,
} from "../../routes";
import { Header } from "../Settings/components/Header";

function GuidelinesPatientPrivacyInner() {
  const navigate = useNavigate();

  const navigateBack = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  return (
    <div style={{ maxWidth: "720px", width: "100%" }}>
      <Header title={"Community Guidelines"} showTitle={false} />
      <div className="flex flex-col pt-4 pb-4 gap-4 pl-4 pr-4">
        <EmojiSilence width={40} height={40} />
        <span className="GuidelinesTitle">Respect Patient Privacy</span>
        <span className="GuidelinesSubTitle">
          Personal Health Information (PHI):{" "}
        </span>
        <span className="GuidelinesDescription">
          In our community, we hold patient privacy very close to our hearts. We
          absolutely insist on keeping personal health information (PHI) that
          could identify a patient confidential.
        </span>
        <span className="GuidelinesDescription">
          This means that names, addresses, contact details, medical records,
          treatment details, etc. must not be shared. If you're sharing
          scenarios or discussions, please make sure they're fully anonymized
          and can't be traced back to anyone.
        </span>
        <span className="GuidelinesDescription">
          Safeguarding patient privacy isn't just about trust and respect—it's
          also about meeting legal and ethical standards. We appreciate your
          understanding and cooperation!
        </span>
      </div>
    </div>
  );
}

export default function GuidelinesPatientPrivacy(props) {
  return (
    <AuthenticatedLayout
      {...props}
      backgroundColor="#FBFDFE"
      hideHeader={true}
      hideFooter={true}
    >
      <GuidelinesPatientPrivacyInner {...props} />
    </AuthenticatedLayout>
  );
}
