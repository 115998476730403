import React, { useState, useEffect } from "react";
import "./Settings.css";
import { Header } from "./components/Header";
import { Form, Radio, Select, Space, Input, DatePicker } from "antd";
import { RubieButton } from "../../components/RubieButton/RubieButton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Medicine } from "../../assets/Medicine.svg";
import { ReactComponent as Suitcase } from "../../assets/Suitcase.svg";
import { useAlert } from "../../hooks/useAlert";
import moment from "moment";
import WithQuery from "../../components/WithQuery";
import { WORK_DETAILS_PAGE_QUERY } from "../../graphql/user";
import { RubieUserService } from "../../services/RubieUser";

function SettingsWorkDetailsPageInner({ data, loading, error }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [clinicalRoleTypeRadio, setClinicalRoleTypeRadio] = useState();
  const { showAlert } = useAlert();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clinicalRoles = data?.clinicalRoles || [];
  const clinicalRoleTypes = ["Leader", "Caregiver"];
  const employmentTypes = data?.employmentTypes || [];
  const specialties = data?.specialties || [];

  const currentUser = data?.currentUser;

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        clinicalRole: currentUser?.clinicalRole || null,
        specialty: currentUser?.specialty || null,
        clinicalRoleType: currentUser?.clinicalRoleType || null,
        employmentType: currentUser?.employmentType || null,
        startedNursingDate: currentUser?.startedNursingDate
          ? moment(currentUser?.startedNursingDate)
          : null,
      });
      if (currentUser?.clinicalRoleType) {
        setClinicalRoleTypeRadio(
          clinicalRoleTypes.indexOf(currentUser?.clinicalRoleType) + 1
        );
      }
    }
  }, [currentUser, form]);

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const gqlValues = {};

      const {
        clinicalRole,
        specialty,
        clinicalRoleType,
        employmentType,
        startedNursingDate,
      } = values;

      if (clinicalRole) {
        gqlValues.clinicalRole = clinicalRole;
      }

      if (specialty) {
        gqlValues.specialty = specialty;
      }

      if (clinicalRoleType) {
        gqlValues.clinicalRoleType =
          clinicalRoleType === 1 ? "Leader" : "Caregiver";
      }

      if (employmentType) {
        gqlValues.employmentType = employmentType;
      }

      if (startedNursingDate) {
        gqlValues.startedNursingDate = startedNursingDate.toISOString();
      }

      const result = await RubieUserService.updateWorkProfile(gqlValues);

      if (result.errors?.length > 0) {
        showAlert("error", "Failed to update work details. Please try again.");
      } else {
        showAlert("success", "Your work details were updated!");
      }
    } catch (error) {
      console.error("Error updating work details:", error);
      showAlert(
        "error",
        "An error occurred while updating work details. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error loading user data</div>;

  const isDisabled = loading || isSubmitting;

  return (
    <div className="SettingsContainer">
      <Header title="Work Details" showTitle />
      <div style={{ border: "none" }} className="SettingsContent">
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={handleSubmit}
          style={{ maxWidth: 600, width: "100%" }}
          validateMessages={{ required: "Required field!" }}
          disabled={isDisabled}
        >
          <Form.Item
            name="clinicalRole"
            label="Current Role"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Clinical Role"
              size="large"
              options={clinicalRoles.map((role) => ({
                value: role,
                label: role.toUpperCase(),
              }))}
            />
          </Form.Item>

          <Form.Item
            name="specialty"
            label="Current Specialty"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="e.g. Pediatric Nurse"
              size="large"
              options={specialties.map((specialty) => ({
                value: specialty,
                label: specialty,
              }))}
            />
          </Form.Item>

          <Form.Item
            className="SettingsStepRadio"
            name="clinicalRoleType"
            label="Role Type"
            rules={[{ required: true }]}
          >
            <Space style={{ width: "100%" }} direction="vertical">
              <Radio
                checked={clinicalRoleTypeRadio === 1}
                onClick={() => setClinicalRoleTypeRadio(1)}
                value={1}
                disabled={isDisabled}
              >
                <div className="SettingsStepRadioItem">
                  <Suitcase />
                  <div className="SettingsStepRadioItemTitle">I'm a Leader</div>
                  <div className="SettingsStepRadioItemDescription">
                    Management, Education, Administration
                  </div>
                </div>
              </Radio>
              <Radio
                checked={clinicalRoleTypeRadio === 2}
                onClick={() => setClinicalRoleTypeRadio(2)}
                value={2}
                disabled={isDisabled}
              >
                <div className="SettingsStepRadioItem">
                  <Medicine />
                  <div className="SettingsStepRadioItemTitle">
                    I'm a Caregiver
                  </div>
                  <div className="SettingsStepRadioItemDescription">
                    Direct Patient Care, Supportive Functions
                  </div>
                </div>
              </Radio>
            </Space>
          </Form.Item>
          <Form.Item
            name="employmentType"
            label="Current Employment"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Current Employment"
              size="large"
              options={employmentTypes.map((type) => ({
                value: type,
                label: type,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="startedNursingDate"
            label="When did you become a clinician?"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: "100%", backgroundColor: "white" }}
              size="large"
              value={currentUser?.startedNursingDate || null}
              format="MM/DD/YYYY"
            />
          </Form.Item>

          <Form.Item name="licenseNumber" label="License Number">
            <Input
              value={currentUser?.licenseNumber || ""}
              disabled
              size="large"
            />
          </Form.Item>
          <div className="SettingsButtons">
            <RubieButton
              variant="ghost"
              onPress={() => navigate("/settings")}
              disabled={isDisabled}
            >
              Cancel
            </RubieButton>
            <RubieButton
              type="submit"
              onPress={form.submit}
              disabled={isDisabled}
              loading={isSubmitting}
            >
              Continue
            </RubieButton>
          </div>
        </Form>
      </div>
    </div>
  );
}

export function SettingsWorkDetailsPage() {
  return WithQuery(
    SettingsWorkDetailsPageInner,
    WORK_DETAILS_PAGE_QUERY,
    {},
    { refetchQueries: [{ query: WORK_DETAILS_PAGE_QUERY }] }
  )();
}
