import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";

import { RubieRoutes } from "./routes";
import RubieApolloClient from "./services/RubieApolloClient";
import { NextUIProviders } from "./providers/NextUIProvider";

import {
  GetStytchClientOptions,
  REACT_APP_STYTCH_PUBLIC_TOKEN,
} from "./helpers/config";
import { AlertProvider } from "./providers/AlertProvider";

const stytchClientOptions = GetStytchClientOptions();
const stytch = new StytchUIClient(
  REACT_APP_STYTCH_PUBLIC_TOKEN,
  stytchClientOptions
);

function App() {
  return (
    <React.StrictMode>
      <StytchProvider stytch={stytch}>
        <ApolloProvider client={RubieApolloClient}>
          <BrowserRouter>
            <NextUIProviders>
              <AlertProvider>
                <RubieRoutes />
              </AlertProvider>
            </NextUIProviders>
          </BrowserRouter>
        </ApolloProvider>
      </StytchProvider>
    </React.StrictMode>
  );
}

export default App;
