import React, { useEffect, useMemo, useState } from "react";
import MultSelect from "../../components/SelectAccordion/SelectAccordion";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "antd";
import { Button } from "@nextui-org/button";
import { isEmpty, isNil } from "lodash";

import { useStreamContext } from "react-activity-feed";

import "./CreatePost.css";

import { Loading } from "../../components/Loading/Loading";
import { CreatePostHeader } from "./CreatePostHeader";
import { FloatButton, Upload } from "antd";
import {
  PictureOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
  BtnUnderline,
} from "react-simple-wysiwyg";

import {
  TIMELINE_PATH,
  FEED_ID_USER,
  FEED_TOPIC_VALUE_LABELS,
  FEED_ID_TOPIC,
  GUIDELINES,
  USER_PROFILE_PATH,
} from "../../routes";
import { useKeyboard } from "../../hooks/useKeyboard";
import { PushPin } from "@phosphor-icons/react";

import { useAlert } from "../../hooks/useAlert";

export default function CreatePost() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [openTopic, setOpenTopic] = useState(false);
  const [selectedTopicFeedId, setselectedTopicFeedId] = useState();
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  // const [bottom, setBottom] = useState(0);

  const { showAlert } = useAlert();

  const navigate = useNavigate();

  const { client, userData, user } = useStreamContext();

  // useEffect(() => {
  //   let initialHeight = window.visualViewport.height;

  //   window.visualViewport.addEventListener('resize', () => {
  //     let currentHeight = window.visualViewport.height;

  //     if (currentHeight < initialHeight) {
  //       let keyboardHeight = initialHeight - currentHeight;
  //       setBottom(keyboardHeight);
  //     } else {
  //       setBottom(0);
  //     }
  //   });
  // }, [])

  const userName = userData?.name;

  const onChange = (e) => {
    setDescription(e.target.value);
  };

  const isDisabled = useMemo(
    () =>
      isEmpty(selectedTopicFeedId) ||
      isNil(selectedTopicFeedId) ||
      title === "" ||
      description === "",
    [title, description, selectedTopicFeedId]
  );

  const onCreatePostHandleClick = async () => {
    if (isDisabled) {
      return;
    }

    setLoading(true);

    const postUUID = crypto.randomUUID();

    const newActivity = {
      verb: "post",

      actor: user,

      title: title,
      text: description,

      object: `post:${postUUID}`,

      topic: FEED_TOPIC_VALUE_LABELS.find(
        (topic) => topic.value === selectedTopicFeedId
      )?.label,

      foreign_id: `post:${postUUID}`,

      to: [FEED_ID_TOPIC + ":" + selectedTopicFeedId],
    };

    if (fileList?.length > 0) {
      // upload the image to Stream
      try {
        const { file } = await client.images.upload(fileList[0].originFileObj);

        // add the image to the attachments
        newActivity.attachments = {
          images: [
            {
              image_url: file,
            },
          ],
        };
      } catch (error) {
        console.error("Error uploading image", error);
        showAlert("error", `Error uploading image: ${error.message}`);
        setLoading(false);
        return;
      }
    }

    client
      .feed(FEED_ID_USER)
      .addActivity(newActivity)
      .then(() => {
        setLoading(false);
        navigate(USER_PROFILE_PATH, { replace: true });
      })
      .catch((error) => {
        console.error("Error creating post", error);
        showAlert("error", `Error creating post: ${error.message}`);

        setLoading(false);
      });
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(
      newFileList.map((file) => {
        file.status = "done";
        return file;
      })
    );
    if (newFileList && newFileList.length > 0) {
      const file = newFileList[0];
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        setPreviewImage(file.url || file.preview);
      }
    }
  };

  const navigateBack = () => {
    if (openTopic) {
      setOpenTopic(false);
    } else {
      if (window.history?.length && window.history.length > 1) {
        navigate(-1);
      } else {
        navigate("/", { replace: true });
      }
    }
  };

  if (loading) {
    return <div className="flex" style={{ height: '100vh' }}><Loading /></div>;
  }

  return (
    <div
      className="CreatePostContainer"
      style={{
        backgroundColor: openTopic ? "#FCF3FE" : "#FCFCFE",
        // overflowY: bottom > 0 ? "hidden" : "auto",
        // height: bottom > 0 ? window.innerHeight : '100vh',
      }}
    >
      <CreatePostHeader
        color={openTopic ? "#EBB0EA" : "#E2DEC6"}
        backgroundTitle={openTopic ? "#FCEAFE" : "#FAF7E8"}
        title={openTopic ? "Select a Topic" : "Craft a Gem"}
        backgroundAction={openTopic ? "#FDE0FE" : "#FAF7E8"}
        onCloseAction={navigateBack}
        showTitle
      />
      <div className="CreatePostContainerBody">
        <MultSelect
          open={openTopic}
          options={FEED_TOPIC_VALUE_LABELS}
          placeholder="Select a Topic"
          value={selectedTopicFeedId}
          setValue={setselectedTopicFeedId}
          setOpen={setOpenTopic}
        />
        {!openTopic && (
          <>
            <div className="px-4 pt-4">
              <Link
                to={GUIDELINES}
                target="_blank"
                className="CreatePostGuidelineToast"
              >
                <PushPin size={16} color="#115841" style={{ marginTop: 2 }} />
                <div className="flex flex-col ml-2">
                  <span className="CreatePostGuidelineToastMessage">
                    Not sure if everything in your post is kosher?
                  </span>
                  <span className="CreatePostGuidelineToastMessage">
                    Check out our <strong>community guidelines.</strong>
                  </span>
                </div>
              </Link>
              <div className="CreatePostPostingText">
                Posting as <strong>{userName}</strong>
              </div>
            </div>
            <div className="CreatePostInputContainer px-1.5">
              <Input
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                className="CreatePostInputTitle"
                variant="borderless"
                placeholder="What’s on your mind?"
              />

              <div
                style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
              >
                <EditorProvider>
                  <Editor
                    // itemRef={editorRef}
                    containerProps={{
                      style: { border: 0 },
                    }}
                    placeholder="Add some details. Everyone can read this, keep personal information to yourself."
                    value={description}
                    onChange={onChange}
                  ></Editor>
                  <Toolbar
                    style={{
                      borderBottom: 0,
                      backgroundColor: "transparent",
                      gap: 10,
                    }}
                  >
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <Upload
                      beforeUpload={() => false}
                      showUploadList={false}
                      fileList={fileList}
                      maxCount={1}
                      accept="image/*"
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      <PictureOutlined
                        style={{
                          fontSize: 16,
                          cursor: "pointer",
                          padding: 4,
                        }}
                      />
                    </Upload>
                  </Toolbar>
                  {previewImage && (
                    <div className="UploadImageFlexDiv">
                      <div className="UploadImageDiv">
                        <img
                          className="UploadImage"
                          src={previewImage}
                          alt="upload file"
                        />
                        <Upload
                          beforeUpload={() => false}
                          showUploadList={false}
                          fileList={fileList}
                          maxCount={1}
                          accept="image/*"
                          onPreview={handlePreview}
                          onChange={handleChange}
                        >
                          <FloatButton
                            icon={<EditOutlined />}
                            style={{ right: 64 }}
                          />
                        </Upload>
                        <FloatButton
                          icon={<CloseOutlined />}
                          onClick={() => {
                            setFileList([]);
                            setPreviewImage(undefined);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="CreatePostRichTextContainer" />
                  <div className="CreatePostButtonContainer">
                    <Button
                      isDisabled={isDisabled}
                      className={`w-full h-full`}
                      radius="none"
                      color={isDisabled ? undefined : "transparent"}
                      onClick={onCreatePostHandleClick}
                    >
                      <span className="CreatePostButtonSubmit">Post</span>
                    </Button>
                  </div>
                </EditorProvider>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
