import React from "react";

import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";

import { useNavigate } from "react-router-dom";
import { CreatePostHeader } from "../../components/CreatePost/CreatePostHeader";

import { ReactComponent as EmojiRedFlag } from "../../assets/EmojiRedFlag.svg";

import "./Guidelines.css";
import { Header } from "../Settings/components/Header";

function ViolateCommunityInner() {
  const navigate = useNavigate();

  const navigateBack = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  return (
    <div style={{ maxWidth: "720px", width: "100%" }}>
      <Header title={"Community Guidelines"} showTitle={false} />
      <div className="flex flex-col pt-4 pb-4 gap-4 pl-4 pr-4">
        <EmojiRedFlag width={40} height={40} />
        <span className="GuidelinesTitle">
          What Happens If I Violate a Community Guideline?
        </span>
        <span className="GuidelinesDescription">
          It seems like one of your comments on Rubie has vanished, right?
          That's because it might have crossed the boundaries set by our
          Community Guidelines.
        </span>
        <span className="GuidelinesDescription">
          We love hearing from you, but let's keep our conversation respectful
          and engaging for everyone! If the guidelines are repeatedly
          overlooked, we'll need to increase moderation on your comments for
          about 30 days. If the trend continues during this period, we might
          have to pause your commenting privileges. Let's foster a positive
          community together!
        </span>
        <span className="GuidelinesDescription">
          Just a friendly reminder that all users need to stick to the terms and
          conditions outlined in Rubie’s Privacy Policy and Terms of Service.
          It's really important to us and the whole Rubie community. If these
          guidelines aren't followed, it could result in some restrictions or
          even termination of your account. Thanks for understanding!
        </span>
      </div>
    </div>
  );
}

export default function GuidelinesViolateCommunity(props) {
  return (
    <AuthenticatedLayout
      {...props}
      backgroundColor="#FBFDFE"
      hideHeader={true}
      hideFooter={true}
    >
      <ViolateCommunityInner {...props} />
    </AuthenticatedLayout>
  );
}
